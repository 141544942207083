import request from '@/scripts/utils/request';

export function login(formData: any) {
    return request({
        url: '/login',
        method: 'post',
        data: formData,
    });
}

export function register(formData: any, commandUrl: string) {
    return request({
        url: commandUrl,
        method: 'post',
        data: formData,
    });
}

export function getSMSToken(mobileNo: string, tokenType: string, tokenPrefix: string = '') {
    const formData = {
        mobile: mobileNo,
        token_type: tokenType,
        token_prefix: tokenPrefix,
    };

    return request({
        url: '/get-sms-token',
        method: 'post',
        data: formData,
    });
}

export function applyLoan(formData: any) {
    return request({
        url: '/apply-loan',
        method: 'post',
        data: formData,
    });
}

export function getLoanSummary() {
    return request({
        url: '/loan-summary',
        method: 'post',
    });
}

export function getLoanDetail(loanId: any) {
    return request({
        url: '/loan-detail',
        method: 'post',
        data: { loan_id: loanId },
    });
}

export function acceptLoanOffer(loanId: any, offerId: any, offerType: any) {
    const postData = { loan_id: loanId, offer_id: offerId, accept_offer_type: offerType };
    console.debug(postData);
    return request({
        url: '/accept-offer',
        method: 'post',
        data: postData,
    });
}

export function cancelLoan(loanId: any) {
    return request({
        url: '/cancel-loan',
        method: 'post',
        data: { loan_id: loanId },
    });
}

export function uploadFile(formData: any, fileId: string) {
    return request({
        url: '/upload-file/' + fileId,
        method: 'post',
        data: formData,
    });
}

export function addLoanAttachmentFile(loanId: any, file: any) {
    const postData = { loan_id: loanId, file_id: file.uid, file_name: file.name };
    // console.debug('add loan attachment file');
    // console.dir(postData);
    return request({
        url: '/add-loan-file',
        method: 'post',
        data: postData,
    });
}

export function contactUs(formData: any) {
    return request({
        url: '/contact-us',
        method: 'post',
        data: formData,
    });
}

export function getImageViewerControl(postData: any) {
    return request({
        url: '/win/doc-viewer',
        method: 'post',
        data: postData,
    });
}

export function getLoanDocsPreviews(loanId: any) {
    const data = { loan_id: loanId };
    return sendCommand('rpc.get-loan-docs-previews', data);
}

export function loadLoanDocIntoDocViewer(loanId: any, fileId: any) {
    const data = { loan_id: loanId, file_id: fileId };
    return sendCommand('rpc.get-loan-docs', data);
}

export function sendCommand(commandName: any, commandData: any) {
    const postData = { command: commandName, data: commandData };
    return request({
        url: '/win/request',
        method: 'post',
        data: postData,
    });
}
