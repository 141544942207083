import moment from 'moment';
import { ColorPicker } from 'element-ui';

export function errHandle(host: any, error: any) {
    console.debug('errHandle');
    console.dir(error);

    // const errorMsg = Object.values(error).map((fieldErrors) => {
    //    return Object.values(fieldErrors).map((fieldError: any) => fieldError.message).join('<br/>');
    // }).join('<br/>');

    let errorMsg = '';
    let errorList: never[] | string[] = [];
    if (typeof error === 'string') {
        console.debug('error is string');
        errorList = [error as string];
    } else {
        concatMessageIntoList(errorList, error);
    }

    console.trace('error:' + errorList);

    errorMsg = errorList.join('<br/>');
    host.$alert(errorMsg, {type: 'error', dangerouslyUseHTMLString: true});

    if (error.errorCode === 'Error.InvalidLoginSession') {
        host.$store.dispatch('logout');
        host.$router.push('/login');
    }

    // if (screen.width <= 760) {
    //     // errorMsg = errorList.join('\r\n');
    //     // alert(errorMsg);
    //     host.$message({message: errorMsg, type: 'error', showClose: true, dangerouslyUseHTMLString: true});
    // } else {
    //     host.$alert(errorMsg, {type: 'error', dangerouslyUseHTMLString: true});
    // }
}

export function showMessage(host: any, msg: any) {
    host.$alert(msg);
    // if (screen.width <= 760) {
    //     host.$message({
    //         showClose: true,
    //         message: msg,
    //         });
    // } else {
    //     host.$alert(msg);
    // }
}

function concatMessageIntoList(list: string[], o: any) {
    // console.debug('concatMessageIntoList');
    // console.dir(o);
    if (o) {
        if (o.message) {
            // console.debug('add message ' + o.message);
            list.push(o.message);
        }

        // console.debug('object values start');
        // console.debug('object keys:' + Object.keys(o));

        Object.keys(o).map((k) => {
            // console.debug('for value:' + (typeof o[k]) + ',' + Array.isArray(o[k]));
            // console.dir(o[k]);
            const v = o[k];
            if (Array.isArray(v)) {
                v.forEach((av) => { concatMessageIntoList(list, av); });
            }
        });
    }
}

export function formatDateTime(cellValue: any) {
    if (cellValue) {
        return moment(cellValue).format('YYYY-MM-DD HH:mm:ss');
    } else {
        return cellValue;
    }
}

export function formatDate(cellValue: any) {
    if (cellValue) {
        return moment(cellValue).format('YYYY-MM-DD');
    } else {
        return cellValue;
    }
}

export function formatNumber(num: any) {
    if (num) {
        return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
    } else {
        return num;
    }
}

export function formatLoanStatus(cellValue: string, expired: string) {
    if (cellValue === 'NEW') {
        return '資料處理中';
    } else if (cellValue === 'AUCTION') {
        if (expired === 'Y') {
            return '競價結束';
            // 請接受出價
        } else {
            return '競價進行中';
        }
    } else if (cellValue === 'CANCELLED') {
        return '已取消';
    } else if (cellValue === 'REJECTED') {
        return '已拒絕';
    } else if (cellValue === 'APPROVED') {
        return '已批核';
    } else if (cellValue === 'ACCEPTED') {
        return '已接受出價';
    } else {
        return cellValue;
    }
}

export function getLoanStatusColor(cellValue: string, expired: string) {
    if (cellValue === 'NEW') {
        // return 'yellow';
        return '';
    } else if (cellValue === 'AUCTION') {
        if (expired === 'Y') {
            return 'info';
        } else {
            return 'warning';
        }
    } else if (cellValue === 'CANCELLED') {
        return 'danger';
    } else if (cellValue === 'REJECTED') {
        return 'danger';
    } else if (cellValue === 'APPROVED') {
        return 'info';
    } else if (cellValue === 'ACCEPTED') {
        return 'success';
    } else {
        return '';
    }
}

export function getLoanStatusIcon(cellValue: string, expired: string) {
    if (cellValue === 'NEW') {
        // return 'yellow';
        return 'el-icon-edit';
    } else if (cellValue === 'AUCTION') {
        if (expired === 'Y') {
            return 'el-icon-video-pause';
        } else {
            return 'el-icon-video-play';
        }
    } else if (cellValue === 'CANCELLED') {
        return 'el-icon-remove';
    } else if (cellValue === 'REJECTED') {
        return 'el-icon-warning-outline';
    } else if (cellValue === 'APPROVED') {
        return 'el-icon-sunny';
    } else if (cellValue === 'ACCEPTED') {
        return 'el-icon-success';
    } else {
        return 'el-icon-success';
    }
}
